import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import Quotes from "components/Quotes"
import ContentBlock from "components/ContentBlock"
import ContentImage from "components/ContentImage"
import PageHero from "components/PageHero"
import Signup from "components/Signup"

if (typeof window !== `undefined`) {
  if (window.location.href.indexOf("meetup") != -1) {
    document.getElementsByTagName("html")[0].style.background = "white"
  }
}

const PageTemplateWhite = props => {
  // console.log("props.data", props.data)
  const { hero, blocks, seo, color } = props.data.wordpressPage
  // console.log("template", props.data.wordpressPage.template)
  //console.log(color)
  return (
    <Layout
      location={props.location}
      type="white"
      navigation
      background="white"
    >
      <SEO seo={seo} />
      <PageHero details={hero} type="white" kleur={color} />
      {blocks.map((item, i) => {
        switch (item.type) {
          case "content":
            return (
              <ContentBlock
                key={i}
                large
                title={item.block.title}
                content={item.block.content}
                link={item.block.link}
                type="white"
                invert
              />
            )
          case "imageLeft":
          case "imageCenter":
          case "imageRight":
            return (
              <ContentImage
                key={i}
                image={item.block.image}
                justify="baseline"
                type={item.block.align}
              />
            )
          case "references":
            return (
              <React.Fragment key={i}>
                <Signup />
                <Quotes details={item.block} type="white" />
              </React.Fragment>
            )
          default:
        }
      })}
    </Layout>
  )
}

export default PageTemplateWhite

export const pageWhiteQuery = graphql`
  query PageWhiteByID($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    wordpressPage(id: { eq: $id }) {
      color
      slug
      template
      blocks {
        type
        block {
          title
          titleMultiple
          link {
            label
            link
          }
          items {
            author
            button {
              link
              text
            }
            company
            description
            link
            quote
            title
            image {
              source_url
              localFile {
                extension
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1921) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          content
          align
          button {
            link
            text
          }
          buttons {
            link
            text
          }
          image {
            src {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      id
      wordpress_id
      hero {
        image {
          src {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          #alt
          title
        }
        subtitle
        title {
          text
          invert
        }
      }
      seo {
        title
        siteName
        description
        image {
          src {
            localFile {
              childImageSharp {
                fixed(width: 1280, height: 853, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
